<template>
	<div class="page--padding flavour">
		<!--<flavour-header class="d-none d-sm-block"/>-->
		<flavour-intro/>
		<flavour-spec/>
		<!--<PralineInfo/>
		<PralineSpecifications/>-->
	</div>
</template>

<script>
    //import FlavourHeader from "@/views/Flavour/flavour-header";
    import FlavourIntro from "@/views/Flavour/flavour-intro";
    import FlavourSpec from "@/views/Flavour/flavour-spec";

    export default {
        name: "Flavour",
        components: {
            //FlavourHeader,
            FlavourIntro,
            FlavourSpec,
        }
    }
</script>

<style lang="scss">
	.flavour {
	}
</style>